import DialogShareAwardEntry from "@/components/DialogShareAwardEntry";
import useDisclosure from "@/hooks/useDisclosure";
import { AwardEntryEntity } from "@/web-client";
import { useMemo } from "react";

interface UseShareDialogReturn {
  openDialog: () => void;
  ShareDialogComponent: JSX.Element;
}

const useShareDialog = (entry: AwardEntryEntity): UseShareDialogReturn => {
  const { openHandler, isOpen, closeHandler } = useDisclosure();
  const ShareDialogComponent = useMemo(() => {
    return (
      <DialogShareAwardEntry
        entry={entry}
        isOpen={isOpen}
        close={closeHandler}
      />
    );
  }, [entry, isOpen, closeHandler]);

  return {
    openDialog: openHandler,
    ShareDialogComponent,
  };
};

export default useShareDialog;
