import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { AxiosResponse } from "axios";
import { DefaultApiAwardsSlugVotedEntryIdsGetRequest } from "@/web-client";
import client from "@/utils/api/client";
import { useLineSession } from "@/hooks/useLineSession";
import { useCallback } from "react";

export const getVotedEntryIds = (
  request: DefaultApiAwardsSlugVotedEntryIdsGetRequest,
  access_token: string,
): Promise<AxiosResponse<number[]>> =>
  client.awardsSlugVotedEntryIdsGet(request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

type useGetVotedEntryIdsReturn = {
  addVotedEntryId: (entryId: number) => void;
} & SWRResponse;

export const useGetVotedEntryIds = (
  award_slug: string,
  config: SWRConfiguration = {},
): useGetVotedEntryIdsReturn => {
  const { session } = useLineSession();
  const response = useSWR(
    session?.access_token ? `/awards/${award_slug}/voted-entry-ids` : null,
    async () => {
      const { data } = await getVotedEntryIds(
        { slug: award_slug },
        session.access_token,
      );

      return data;
    },
    config,
  );
  const { data, mutate } = response;

  const addVotedEntryId = useCallback(
    (entryId: number) => {
      if (data) {
        mutate([...data, entryId], false);
      }
    },
    [data, mutate],
  );

  return {
    ...response,
    addVotedEntryId,
  };
};
