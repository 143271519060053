import { useGetVotedEntryIds } from "@/features/vote/api/getVotedEntryIds";
import { useMemo } from "react";

export const useHasVoted = (
  award_slug: string,
  targetEntryId: number,
): boolean | undefined => {
  const { data: votedIds, isLoading, error } = useGetVotedEntryIds(award_slug);

  const hasVoted = useMemo<boolean>(() => {
    if (isLoading || error) return undefined;

    return (votedIds || []).includes(targetEntryId);
  }, [votedIds, targetEntryId, isLoading, error]);

  return hasVoted;
};
