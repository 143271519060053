import { useVote } from "@/features/vote/hooks/useVote";
import { useLineSession } from "@/hooks/useLineSession";
import { AwardEntryEntity } from "@/web-client";
import { useCallback } from "react";

interface VoteButton {
  clickHandler: VoidFunction;
}

export const useVoteButton = (
  slug: string,
  entry: AwardEntryEntity,
  onVoted: VoidFunction,
  openDialogLine: VoidFunction,
): VoteButton => {
  const { expired } = useLineSession();
  const { vote } = useVote(slug, entry, onVoted);

  const clickHandler = useCallback(() => {
    // セッションがないもしくは期限切れならばLINEログインを促すダイアログを表示
    if (expired) {
      openDialogLine();

      return;
    }

    vote();
  }, [expired, openDialogLine, vote]);

  return {
    clickHandler,
  };
};
