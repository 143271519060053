import { FC, useMemo } from "react";
import Dialog from "@/components/Dialog";
import IconCopyButton from "@/components/IconCopyButton";
import IconButton from "@/components/IconButton";
import IconX from "@/assets/imgs/svg/icon_x.svg";
import IconFacebook from "@/assets/imgs/svg/icon_facebook.svg";
import { openXForAwardShare } from "@/utils/openXForAwardShare";
import { openFacebookForAwardShare } from "@/utils/openFacebookForAwardShare";
import { AwardEntryEntity } from "@/web-client";
import { useGetProject } from "@/features/project/api/getProject";
import { PUBLIC_URL } from "@/config/env";

interface Props {
  isOpen: boolean;
  close: VoidFunction;
  entry: AwardEntryEntity;
}

const DialogShareAwardEntry: FC<Props> = ({ isOpen, close, entry }) => {
  const { data: project } = useGetProject(entry.project_id);

  const url = useMemo(() => {
    if (!project) return undefined;

    return `${PUBLIC_URL}/projects/${project.id}`;
  }, [project]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      header={
        <div
          className="aspect-video bg-cover bg-no-repeat bg-center bg-gray-50"
          style={{
            backgroundImage: `url(${project?.main_image?.urls.small})`,
          }}
        />
      }
      title={"投票ありがとうございます"}
      closeBtnColor="neutral"
    >
      {project && (
        <div className="grid gap-16">
          <p className="text-sm">
            「{project.title}」
            に投票ありがとうございます！このプロジェクトをシェアして、より多くの人に知ってもらいませんか？
          </p>

          <div className="flex flex-row items-center gap-16 justify-center">
            <IconButton
              onClick={() => openXForAwardShare(url, project.title)}
              rounded
              size="large"
              aria-label={`Xで「${project.title}」をシェアする`}
            >
              <IconX />
            </IconButton>
            <IconButton
              onClick={() => openFacebookForAwardShare(url)}
              rounded
              size="large"
              aria-label={`Facebookで「${project.title}」をシェアする`}
            >
              <IconFacebook />
            </IconButton>
            <IconCopyButton
              copyText={url}
              message="リンクをクリップボードにコピーしました"
              ariaLabel="リンクをコピー"
            />
          </div>
        </div>
      )}
    </Dialog>
  );
};
export default DialogShareAwardEntry;
