import {
  setPreVoteEntry as setPreVoteEntryOnStore,
  clearPreVoteEntry as clearPreVoteEntryOnStore,
} from "@/stores/history";
import { RootState } from "@/stores/rootReducer";
import { AwardEntryEntity } from "@/web-client";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface SavedEntry {
  preVoteEntry: AwardEntryEntity;
  setPreVoteEntry: (entry: AwardEntryEntity) => void;
  clearPreVoteEntry: () => void;
}

export const useSavedEntry = (): SavedEntry => {
  const dispatch = useDispatch();

  const { preVoteEntry } = useSelector((state: RootState) => state.history);

  const setPreVoteEntry = useCallback(
    (entry: AwardEntryEntity) => dispatch(setPreVoteEntryOnStore(entry)),
    [dispatch],
  );

  const clearPreVoteEntry = useCallback(
    () => dispatch(clearPreVoteEntryOnStore()),
    [dispatch],
  );

  return {
    preVoteEntry,
    clearPreVoteEntry,
    setPreVoteEntry,
  };
};
