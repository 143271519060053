import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import { useMemo } from "react";

interface LineSession {
  session: Session;
  expired: boolean;
  status: "loading" | "authenticated" | "unauthenticated";
  isAuthenticated: boolean;
}

export const useLineSession = (): LineSession => {
  const { data: session, status } = useSession();
  const expired = useMemo(() => {
    if (!session) return true;

    return !!session.error;
  }, [session]);
  const isAuthenticated = useMemo(() => {
    return status === "authenticated" && !expired;
  }, [session]);

  return {
    session,
    expired,
    status,
    isAuthenticated,
  };
};
