export const openXForAwardShare = (url: string, projectTitle: string): void => {
  const shareText = encodeURIComponent(
    `TECTURE AWARDで「${projectTitle}」に投票しました！`,
  );
  const shareUrl = encodeURIComponent(url);
  const hashtag = encodeURIComponent("TECTUREAWARD");

  window.open(
    `https://x.com/intent/tweet?text=${shareText}&url=${shareUrl}&hashtags=${hashtag}`,
    "_blank",
  );
};
