import { useLineSession } from "@/hooks/useLineSession";
import useProgress from "@/hooks/useProgress";
import { useCallback } from "react";
import { postVote } from "@/features/vote/api/postVote";
import useAnalytics from "@/hooks/useAnalytics";
import { AwardEntryEntity } from "@/web-client";
import { useGetVotedEntryIds } from "@/features/vote/api/getVotedEntryIds";

export interface Vote {
  vote: () => void;
}

export const useVote = (
  awardSlug: string,
  entry: AwardEntryEntity,
  onVoted?: VoidFunction,
): Vote => {
  const { event } = useAnalytics();
  const progress = useProgress();
  const { session } = useLineSession();
  const { addVotedEntryId } = useGetVotedEntryIds(awardSlug);

  const vote = useCallback(async () => {
    progress(
      async () => {
        await postVote(
          {
            slug: awardSlug,
            entryId: entry.id,
          },
          session.access_token,
        );

        event("vote", { item_id: `PJ_${entry.project_id}` });

        // 投票済みのエントリーIDを更新
        addVotedEntryId(entry.id);

        onVoted && onVoted();
      },
      {
        errorMessage: "投票に失敗しました",
      },
    );
  }, [awardSlug, onVoted, progress, session, addVotedEntryId, event, entry]);

  return {
    vote,
  };
};
