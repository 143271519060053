import client from "@/utils/api/client";
import { DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest } from "@/web-client";
import { AxiosResponse } from "axios";

export const postVote = (
  request: DefaultApiAwardsSlugEntriesEntryIdVotesPostRequest,
  access_token: string,
): Promise<AxiosResponse<void>> =>
  client.awardsSlugEntriesEntryIdVotesPost(request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
