import { useHasVoted } from "@/features/vote/hooks/useHasVoted";
import useShareDialog from "@/features/vote/hooks/useShareDialog";
import { useVote } from "@/features/vote/hooks/useVote";
import { AwardEntryEntity } from "@/web-client";
import { FC, useEffect, useState } from "react";

interface Props {
  awardSlug: string;
  entry: AwardEntryEntity;
  onVoted?: VoidFunction;
}

const AuthRedirectVote: FC<Props> = ({ awardSlug, entry, onVoted }) => {
  const [requested, setRequested] = useState(false);
  const { ShareDialogComponent, openDialog } = useShareDialog(entry);
  const hasVoted = useHasVoted(awardSlug, entry.id);
  const { vote } = useVote(awardSlug, entry, () => {
    openDialog();

    if (onVoted) onVoted();
  });

  useEffect(() => {
    if (hasVoted === false && !requested) {
      setRequested(true);
      vote();
    }
  }, [hasVoted, vote, requested, setRequested]);

  return <>{ShareDialogComponent}</>;
};

export default AuthRedirectVote;
