import { FC, useCallback } from "react";
import IconLink from "@/assets/imgs/svg/icon_link.svg";
import IconButton from "@/components/IconButton";
import copy from "copy-to-clipboard";
import useSnackbar from "@/hooks/useSnackbar";

interface Props {
  copyText: string;
  message: string;
  ariaLabel?: string;
}

const IconCopyButton: FC<Props> = ({ copyText, message, ariaLabel }) => {
  const { show } = useSnackbar();
  const clickHandler = useCallback(() => {
    copy(copyText);
    show(message, "success");
  }, [copyText, show, message]);

  return (
    <IconButton
      onClick={clickHandler}
      rounded
      size="large"
      aria-label={ariaLabel}
    >
      <IconLink />
    </IconButton>
  );
};
export default IconCopyButton;
